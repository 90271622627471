@import "../../css/mixin.scss";

.wrapper {
  margin: 40px 20px 20px 20px;

  .dummy {
    line-height: 120px;
    font-size: 30px;
    color: #bbb;
    background: #eee;
      text-align: center;
  }
}

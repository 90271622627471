.App-conteiner {
  background: #fff;
  position: relative;
  min-height: 100vh;

  .App-conteiner-inner {
    @media screen and (min-width: 851px) {
      padding-bottom: 90px;
    }

    @media screen and (max-width: 850px) {
      padding-bottom: 180px;
    }
  }
}

/* commom - a */
a {
  color: #222;
  text-decoration: none;

  &:visited,
  &:active,
  &:hover {
    color: #222;
    text-decoration: none;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.8;
  }
}

/* spin */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** モーダルダイアログ */

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(220,220,220, 0.2);
  z-index: 999;

  .containre {
    min-height: 200px;
    min-width: 300px;
    border-radius: 6;
    background: #fff;
    box-shadow: 1px 1px 10px #ddd;
  }
}

@import "../../css/values.scss";

.wrapper {
  margin: 20px;

  .title {
    font-size: 24px;
    border-bottom: 10px;
  }

  .loading {
    font-size: 20px;
    margin-top: 20px;

    .spinner_icon {
      animation: spin 1.5s linear 0s infinite;
      margin-right: 8px;
      color: $keyColor;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .sitemap_container {
    padding: 20px;
    border-radius: 5px;
    font-size: 12px;
    background: #eee;
    color: #555;
  }
}

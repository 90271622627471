@import "../../css/values.scss";
@import "../../css/mixin.scss";

.wrapper {
  margin-bottom: 20px;
  display: flex;
  position: relative;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 6px;

  .info_container {
    margin-left: 10px;

    .item {
      font-size: 14px;
      margin: 4px 0 4px 0;

      .single_label {
        display: inline-block;
        width: 100px;
        border-right: 1px solid #eee;
        margin-right: 10px;
      }

      @mixin status() {
        display: inline-block;
        width: 80px;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
      }

      .status_draft {
        @include status();
        background: rgb(217, 255, 206);
        color: #333;
      }

      .status_open {
        @include status();
        background: $keyColor;
        color: #222;
      }
      .status_close {
        @include status();
        background: #ccc;
        color: #777;
      }
    }

    .title {
      font-size: 20px;
    }

    .contents {
      padding: 4px;
      font-size: 16px;
    }
  }

  .image {
    width: 200px;

    .post_image {
      width: 200px;
    }

    .post_image_thumb {
      width: 150px;
    }
  }

  .box {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px;
    min-height: 20px;
    width: 500px;
  }

  .sub_text {
    font-size: 10px;
    color: #888;
  }

  .mode_button {
    @include button_basic();

    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 6px;
  }

  .form_container {
    .item {
      margin: 0 0 10px;
      .label {
        font-size: 14px;
      }

      .input_text_s,
      .input_text {
        padding: 4px 8px;
        border: 1px solid #ddd;
        line-height: 25px;
        width: 500px;
      }

      .input_text_s {
        width: 100px;
      }

      .text_area {
        padding: 4px 8px;
        border: 1px solid #ddd;
        line-height: 25px;
        width: 500px;
        height: 120px;
      }

      .select_box {
        min-width: 120px;
        padding: 4px 8px;
        border: 1px solid #ddd;
      }
    }

    .update_button {
      @include button_basic();

      background: $keyColor;
      text-align: center;
      width: 150px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 30px;
    }
  }
}

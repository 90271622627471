@import "../../css/values.scss";
@import "../../css/mixin.scss";

.wrapper {
  padding: 20px;

  .title {
    font-size: 24px;
    border-bottom: 10px;
  }

  .button_container {
    margin: 20px;
  }

  .button {
    @include button_common();
  }
}

@import "../css/mixin.scss";

.wrapper {
  $size: 255px;

  .posts_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px;

    .post_link {
      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.8;
      }
    }

    .item {
      position: relative;
      margin: 4px;
      border: 1px solid #eee;
      width: $size;
      height: $size;
      border-radius: 5px;

      .title {
        position: absolute;
        padding: 4px;
        right: 0;
        width: $size;
        max-height: 60px;
        overflow: hidden;
        bottom: 10px;
        background: rgba(255, 255, 255, 0.7);
        color: #222;
        text-align: right;
        word-break: break-all;
        font-size: 14px;
        text-shadow: 0px 0px 2px #999;
      }

      .post_image {
        width: $size;
        border-radius: 5px;
      }
    }
  }

  .more_button_container {
    text-align: center;
    margin-top: 20px;

    .more_button {
      @include button_basic;

      display: inline-block;
      line-height: 25px;
      border: 1px solid #ddd;
      border-radius: 6px;
      text-align: center;
      width: 100px;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  width: 50%;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #444;
}

body {
    color: #222;
}

/* a */
a {
  color: #222;
  text-decoration: none;

  &:visited,
  &:active,
  &:hover{
    color: #222;
    text-decoration: none;
  }

  &:hover{
    opacity: 0.6;
  }

  &:active{
    opacity: 0.8;
  }
}

/* spin */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

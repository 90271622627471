.wrapper {
  margin: 20px;
  padding: 6px;
  font-size: 14px;

  .title {
    font-size: 22px;
  }

  .item {
    margin: 10px 5px;

    .link {
      border-bottom: 1px solid #ddd;
      font-size: 14px;
    }
  }
}

.backlink_wrapper {
  margin: 5px;
}

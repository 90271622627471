.wrapper {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 4px 0 0 6px;

    .logo {
      height: 25px;
    }

    .right_container {
      padding-right: 5px;

      .mode {
        font-size: 14px;
        border: 1px solid #eee;
        padding: 1px 8px;
        border-radius: 4px;
        font-size: 10px;
        color: #888;
        user-select: none;
      }

      .sign_out {
        padding-left: 10px;
        font-size: 12px;
        letter-spacing: -1.5px;
      }

      .fa-sign-out {
        padding-right: 2px;
      }
    }
  }
}

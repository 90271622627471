.wrapper {
  margin: 20px;

  .header {
    font-size: 20px;
    margin-bottom: 10px;
    padding-left: 4px;
    border-bottom: 1px solid #ddd;

    .icon {
      font-size: 17px;
      margin-right: 5px;
    }
  }

  .container {
    border: 1px solid #eee;
    margin: 15px 5px;
    padding: 10px;
    border-radius: 5px;

    .title {
      font-size: 16px;
      border-bottom: 1px solid #ddd;

      .date {
        font-size: 11px;
        margin-left: 5px;
      }
    }

    .contents {
      padding: 10px 5px;
      font-size: 14px;
    }
  }
}

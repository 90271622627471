@import "../../css/mixin.scss";

.wrapper {
  border-bottom: 1px solid #eee;
  margin: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3px;

  .container {
    display: flex;

    .header {
      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 8px;
      font-size: 11px;
      margin-right: 5px;
      line-height: 16px;
      padding: 0 15px;
      min-width: 50px;
      text-align: center;
    }

    .contents {
      display: inline-block;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 5px;
      white-space: nowrap;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;
      margin-top: 1px
    }
  }

  .more_button {
    @include button_basic();

    font-size: 11px;
    min-width: 85px;
    text-align: center;
  }
}

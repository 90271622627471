.wrapper {
  .container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 50px;
    color: #f0a400;
  }

  .spinner_08s {
    animation: spin 1.5s linear 0s infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

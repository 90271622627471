.wrapper {
  margin: 20px;

  .head {
    font-size: 18px;
  }

  .inner {
    margin: 10px;

    .item {
      margin: 15px 0;
      font-size: 12px;

      .head {
        font-size: 14px;
        margin-top: 15px;
      }

      .contents {
        margin: 6px;
      }
    }
  }
}

body {
  margin: 0;
  font-family: "M PLUS Rounded 1c";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  margin: 0 auto;
  color: #222;

  @media screen and (min-width: 851px) {
    width: 850px;
  }

  @media screen and (max-width: 850px) {
    width: 100%;
  }
}

@import "../../css/mixin.scss";

.wrapper {
  display: flex;
  justify-content: center;

  .contents {
    margin: 20px;
    width: 80%;
    border: 1px solid #eee;
    border-radius: 4px;

    .name{
      padding: 4px;
      text-align: right;
    }
  }
}

.wrapper {
  margin: 20px;
  .container {
    .back_to_home {
      border-bottom: 1px solid #eee;
      .icon {
        margin-right: 5px;
      }
    }

    .contents {
      padding-top: 10px;

      .title {
        font-size: 24px;
        text-shadow: 0 0 2px #ccc;
      }

      .options {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .tag {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 5px;
          font-size: 13px;
          border-radius: 4px;
          border: 1px solid #eee;
          padding: 2px 8px;

          .tag_icon{
            margin-right: 5px;
            font-size: 14px;
          }
        }


      .post_data {
        min-width: 150px;
        font-size: 14px;
        text-align: right;
      }

      }

      .image_container {
        text-align: center;
        margin: 10px;
        .post_image {
          max-width: 500px;
          border-radius: 10px;
          border: 1px solid #f0f0f0;
        }
      }

      .information {
        border: 1px solid #eee;
        margin: 20px;
        padding: 10px 20px;
        border-radius: 5px;

        .icon {
          font-size: 15px;
          margin-right: 5px;
        }

        .header {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .text {
          font-size: 14px;
          padding: 5px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

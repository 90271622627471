@import "../css/values.scss";

.wrapper {
  background: $footerBackground;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .copyright,
  .contents {
    padding: 4px;
    padding: 4px;
    font-size: 11px;
  }

  @media screen and (min-width: 851px) {
    .contents {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        padding: 4px 10px;
        font-size: 16px;
      }

      .links {
        font-size: 12px;
        padding-left: 10px;

        .item {
          display: inline-block;
          padding: 0 10px;
        }
      }
    }

    .copyright {
      text-align: center;
    }
  }

  @media screen and (max-width: 850px) {
    .contents {
      .title {
        padding: 4px 10px;
        font-size: 18px;
      }

      .links {
        font-size: 16px;
        padding-left: 6px 4px;

        .item {
          display: block;
          padding: 0 10px;
        }
      }
    }

    .copyright {
      text-align: right;
    }
  }
}

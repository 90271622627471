.wrapper {
  margin: 20px;

  .title_container {
    text-align: center;

    img {
      height: 250px;
    }
  }

  .hr {
    margin-top: 20px;
    margin-bottom: 10px;
    border: 0 solid #ddd;
    border-top-width: 1px;
  }

  .content_container {
    margin: 20px;

    .head {
      font-size: 18px;
    }

    .inner {
      margin: 5px;

      .item {
        .head {
          font-size: 15px;
          text-shadow: 1px 1px 2px #ddd;
        }

        .contents {
          margin-top: 5px;
          padding-left: 4px;
          font-size: 15px;
        }

        margin: 15px 0;
        font-size: 14px;
        padding: 10px;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

@import "../css/values.scss";
@import "../css/mixin.scss";

.wrapper {
  .header_title {
    margin: 10px;
    font-size: 24px;
  }

  .form {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 6px;

    .complete_message {
      padding: 5px;
      font-size: 20px;
    }

    .form_items {
      display: flex;

      .uploading {
        font-size: 20px;

        .spinner_icon {
          animation: spin 1.5s linear 0s infinite;
          margin-right: 8px;
          color: $keyColor;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .file_select_button {
        @include button_basic();

        border: 1px solid #ddd;
        border-radius: 6px;
        width: 150px;
        text-align: center;
        background: #fff;
        font-size: 14px;
        line-height: 30px;
      }

      .submit_button,
      .submit_button_disable {
        border-radius: 6px;
        width: 150px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        user-select: none;
        margin-left: 20px;
      }

      .submit_button_disable {
        border: 1px solid #ddd;
        background: #eee;
        color: #ddd;
        cursor: not-allowed;
      }

      .submit_button {
        @include button_basic();
        background: $keyColor;
      }
    }

    .image_previews {
      margin-bottom: 20px;

      .preview {
        img {
          width: 80px;
          border: 1px solid #eee;
          margin-right: 10px;
        }
      }
    }
  }

  .posts_container {
    margin: 20px;
  }

  .more_button_container {
    text-align: center;

    .more_button {
      @include button_basic();

      display: inline-block;
      line-height: 25px;
      border: 1px solid #ddd;
      border-radius: 6px;
      text-align: center;
      width: 100px;
    }
  }
}

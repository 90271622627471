.wrapper {

  color: #444;
  display: flex;
  align-items: center;
  min-height: 100vh;
  position: relative;
  justify-content: center;

  .message {
    font-size: 40px;
    text-align: center;
  }

  .back_icon_button {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 20px;
    font-size: 40px;
  }

  .link_direction_none {
    color: #f0a400;
    user-select: none;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.8;
    }
  }
}

@mixin button_basic() {
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.8;
  }
}

@mixin button_common() {
  @include button_basic();

  display: inline-block;
  border: 1px solid #eee;
  line-height: 30px;
  border-radius: 6px;
  min-width: 150px;
  text-align: center;
}

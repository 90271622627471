@import "../../css/values.scss";
@import "../../css/mixin.scss";

.wrapper {
  margin: 20px;

  .form_container {
    .item {
      margin: 0 0 10px;
      .label {
        font-size: 14px;
      }

      .input_text_s,
      .input_text {
        padding: 4px 8px;
        border: 1px solid #ddd;
        line-height: 25px;
        width: 500px;
      }

      .input_text_s {
        width: 100px;
      }

      .text_area {
        padding: 4px 8px;
        border: 1px solid #ddd;
        line-height: 25px;
        width: 500px;
        height: 120px;
      }

      .select_box {
        min-width: 120px;
        padding: 4px 8px;
        border: 1px solid #ddd;
      }
    }

    .submit_button {
      @include button_basic;

      background: $keyColor;
      text-align: center;
      width: 150px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 30px;
    }
  }

  .list_item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .button_container {
    text-align: right;

    .op_button {
      @include button_basic;

      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 4px;
      line-height: 20px;
      font-size: 12px;
      margin: 0 10px;
      padding: 0 10px;
    }
  }

  .more_button_container {
    text-align: center;

    .more_button {
      @include button_basic;

      display: inline-block;
      line-height: 25px;
      border: 1px solid #ddd;
      border-radius: 6px;
      text-align: center;
      width: 100px;
    }
  }

  .error_info {
    font-size: 12px;
    color: #f00;
    margin-top: 5px;
  }
}

.wrapper {
  font-size: 12px;
  text-align: right;

  @media screen and (min-width: 851px) {
    position: absolute;
    top: 10px;
    right: 0;
    border-radius: 4px 0 0 4px;
  }

  .header {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  .item {
    margin: 3px;
  }

  .inner_dev {
      padding: 6px;
    @media screen and (min-width: 851px) {
      //    background: rgba(0, 0, 0, 0.1);
      background: rgb(255, 0, 0, 0.1);
    }

    @media screen and (max-width: 850px) {
      background: rgba(255, 0, 0, 0.2);
    }
  }

  .inner {
      padding: 6px;
    background: rgb(255, 255, 255, 0.2);

        @media screen and (min-width: 851px) {
      background: rgba(0, 255, 0, 0.05);
    }

    @media screen and (max-width: 850px) {
      background: rgba(0, 255, 0, 0.1);
    }
  }
}

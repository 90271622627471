.wrapper {

  width: 400px;
  padding: 20px;

  .flex_container{
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }

  .cancel_button {
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    user-select: none;
    font-size: 14px;
    color: #555;
  }
}

.wrapper {
  margin: 30px;

  .image {
    text-align: center;

    img {
      width: 256px;
      height: 256px;
    }
  }

  .container {
    margin: 30px 10px;

    .header {
      font-size: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }

    .inner {
      .item {
        font-size: 14px;
      }
    }
  }

  .question_container {
    display: flex;
    line-height: 50px;

    .head {
      flex-shrink: 0;
      flex-basis: 50px;
      color: #78acd7;
      padding-left: 13px;
      font-weight: bold;
      font-size: 16px;
    }

    .contents {
      font-weight: bold;
      flex-grow: 1;
    }
  }

  .answer_container {
    display: flex;
    background: #fcfcfc;
    padding-top: 10px;
    padding-bottom: 15px;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;

    .head {
      flex-shrink: 0;
      flex-basis: 50px;
      color: #f8ab1e;
      line-height: 25px;
      padding-left: 13px;
      font-size: 16px;
      font-weight: bold;
    }

    .contents {
      line-height: 25px;
    }
  }

  .hr {
    margin-top: 20px;
    margin-bottom: 10px;
    border: 0 solid #ddd;
    border-top-width: 1px;
  }

  .title_container {
    text-align: center;

    img {
      height: 40px;
    }
  }

  .home_icon_container_header {
    margin-bottom: 5px;
  }

  .detail {
    font-size: 13px;
    margin-bottom: 10px;
  }

  // 801以上
  @media screen and (min-width: 801px) {
    .image_container {
      text-align: center;
      width: 250px;
      margin: 20px;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 10px;

      img {
        width: 90%;
      }
    }

    .home_icon_container {
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 10px;

      .img {
        display: block;
        margin: 0 auto;
        width: 250px;
      }
    }
  }

  // 800まで
  @media screen and (max-width: 800px) {
    .image_container {
      text-align: center;
      width: 50%;
      margin: 20px auto;
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 10px;
      font-size: 14px;

      img {
        width: 100%;
      }
    }

    .home_icon_container {
      border: 1px solid #ddd;
      border-radius: 6px;
      padding: 10px;

      .img {
        display: block;
        margin: 0 auto;
        width: 70%;
      }
    }
  }
}
